<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                <el-input
                  :maxlength="11"
                  v-model.trim="formInline.mobile"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Import_time')">
                <el-date-picker
                  v-model="rangeDate"
                  @change="changeTime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="coL_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" @click="addFrom" icon="el-icon-plus">添加 </el-button>

              <el-button type="info" icon="el-icon-upload" plain @click="imporT"
                >批量{{ $t('button.import') }}</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
          <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm()"
            >{{ $t('button.reset') }}</el-button
          > -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        title="添加电话"
        :visible.sync="dialogVisible"
        class="dialogHead"
        width="450px"
      >
        <el-form
          ref="form"
          @submit.native.prevent="submit"
          :rules="rules"
          :model="addFormline"
          label-width="100px"
        >
          <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
            <el-autocomplete
              valueKey="plateNumber"
              class="inline-input"
              v-model="addFormline.plateNumber"
              :fetch-suggestions="querySearchAsync"
              placeholder="车牌号格式:蓝:粤A12345"
              :trigger-on-focus="false"
              @select="handleSelect1"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
            <el-input
              style="width: 202px"
              maxlength="11"
              placeholder="请输入手机号"
              v-model="addFormline.mobile"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="dialogButton" style="text-align: center">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">{{ $t('button.preservation') }}</el-button>
        </div>
      </el-dialog>
      <el-dialog
        class="dialogHead"
        title="批量导入"
        :visible.sync="dialogVisibleupload"
        width="600px"
      >
        <div><span style="color: red">*</span>导入文件</div>
        <div style="margin-left: 100px">
          <div>
            <el-upload
              class="upload-demo"
              multiple
              drag
              ref="upload"
              :headers="header"
              :on-success="uploadSuccess"
              :limit="1"
              :on-exceed="handleExceed"
              :data="upData"
              action="/acb/2.0/carMobile/importThirdMobile"
              :on-change="picChange"
              :file-list="fileList"
              :auto-upload="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">点击上传文件或拖拽上传文件</div>
              <!-- <el-input
          v-model="fileName"
          style="width: 200px; float: left; margin-right: 5px"
        ></el-input> -->
              <!-- <el-button slot="trigger" @click="uploadClick" type="primary"
          >浏览...</el-button
        > -->
            </el-upload>
          </div>
          <div>
            <el-button type="text" @click="exportModel">下载导入模板</el-button>
          </div>
          <div>(提示：由于接口查询速度问题，建议导入数据量在2000条以内)</div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="dialogVisibleupload = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload" :loading="loading1">{{ $t('button.import') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
const now = new Date();
const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
start.setHours(0);
start.setMinutes(0);
start.setSeconds(0);
now.setHours(23);
now.setMinutes(59);
now.setSeconds(59);
export default {
  name: "staggeredOrder",
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        return callback();
      }
    };
    let checkPlate = (rule, value, callback) => {
      let rel =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{4,5}([A-Z0-9挂学警港澳使]{1}|应急)$/;
      if (rel.test(value)) {
        callback();
      } else {
        callback("车牌号格式不正确");
      }
    };
    return {
      upData: {
        "Session-Id": sessionStorage.token,
      },
      header: {
        "Session-Id": sessionStorage.token,
      },
      dialogVisible: false,
      dialogVisibleupload: false,
      fileName: "",
      plateNumber: "",
      rowData: {},
      fileList: [],
      temParkData: {},
      temParkData1: {},
      infoSize: 4,
      exportData: {},
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      loading1: false,
      modelvalue1: "",
      rule: {},
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "",
        },
        {
          prop: "mobile",
          label: "电话",
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          formatter: (row) => {
            return row.plateNumber && row.plateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.plateNumber;
          },
          width: "",
        },
        {
          prop: "reportTime",
          label: "导入时间",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
      ],

      tableData: [],
      page: 1,
      size: 15,
      pickerOptions: {
        disabledDate: (val) => {
          // const now = new Date().getTime();
          return val.getTime() > now;
        },
      },
      rangeDate: [dateFormat(start), dateFormat(now)],
      formInline: {
        carId: "",
        mobile: "",
        startReportTime: dateFormat(start),
        endReportTime: dateFormat(now),
      },
      addFormline: {
        plateNumber: "",
        mobile: "",
      },
      rules: {
        plateNumber: [
          {
            required: true,
            message: "车牌号不能为空",
            trigger: "blur",
          },
          // {
          //   validator: checkPlate,
          //   trigger: "blur",
          // },
        ],
        mobile: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    changeTime(rangeDate) {
      if (rangeDate) {
        this.formInline.startReportTime = rangeDate[0];
        this.formInline.endReportTime = rangeDate[1];
      } else {
        this.formInline.startReportTime = "";
        this.formInline.endReportTime = "";
      }
    },
    // 时间判断
    showLog() {
      if (this.rangeDate) {
        if (this.rangeDate[0] && this.rangeDate[1]) {
          let time = new Date(this.rangeDate[1]) - new Date(this.rangeDate[0]);
          time = time / (24 * 60 * 60 * 1000);
          if (time > 366 && this.formInline.carId == "") {
            this.$alert("查询时间不能超过366天");
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    // 导入
    imporT() {
      this.dialogVisibleupload = true;
      this.picFlag = false;
      this.fileName = "";
      // this.$refs.upload.clearFiles();
    },
    // 下载模板
    exportModel() {
      let opt = {};
      opt = {
        scope: 1,
      };
      // exportExcelNew("/acb/2.0/bar/downloadExcelTemplate", opt);
      exportExcelNew("/acb/2.0/carMobile/thirdMobile/downloadExcelTemplate", opt);
    },
    exportFile() {
      let opt = {};
      opt = {
        barCode: this.formInline.barCode,
        areaId: this.formInline.areaId,
        operationId: this.formInline.operationId,
        parkId: this.formInline.parkId,
        own: this.formInline.own,
      };
      // /acb/2.0/specialplate/export
      exportExcelNew("/acb/2.0/bar/platform/export", opt);
    },
    submitUpload() {
      if (this.picFlag) {
        this.$refs.upload.submit();
        this.loading1 = true;
      } else {
        this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    picChange(file, row) {
      if (file.name && !file.response) {
        this.fileName = file.name;
        this.picFlag = true;
      } else {
        this.$refs.upload.clearFiles();
        this.fileName = "";
        this.picFlag = false;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一个文件`);
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res.state == 0) {
        this.$message({
          type: "info",
          message: "文件上传成功",
        });
        this.dialogVisibleupload = false;
        this.pageNum = 1;
        this.searchData();
        this.loading1 = false;
      } else {
        this.$alert(res.desc + " 请重新选择文件！！！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        this.loading1 = false;
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    addFrom() {
      this.dialogVisible = true;
      this.addFormline.plateNumber = "";
      this.$refs["form"].resetFields();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/carMobile/addThirdMobile", {
              data: {
                ...this.addFormline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                //  this.formInline.carId = "";
                this.searchData();
              } else {
                this.$alert(res.desc, "提示");
              }
            });
        }
      });
    },
    /* 车牌号 */
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          res.value.forEach((v) => {
            v.value = v.plateNumber;
          });
          cb(res.value);
        }
      });
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      // this.addFormline.plateNumber = item.plateNumber;
    },
    handleSelect1(item) {
      this.addFormline.plateNumber = item.plateNumber;
    },
    searchData() {
      let flag = this.showLog();
      if (flag) {
        this.$axios
          .get("/acb/2.0/carMobile/queryThirdMobile", {
            data: {
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              ...this.formInline,
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
      if (!this.formInline.parkId) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
    },
    // 搜索
    searParkRecordList() {
      // console.log(this.formInline);
      this.loading = true;
      if (this.formInline.parkId == "") {
        this.formInline.parkName = "";
      }
      this.searchData();
    },
  },
  components: {},
  created() {
    if (this.$route.params.tradeNo) {
      this.formInline.tradeNo = this.$route.params.tradeNo;
      this.rangeDate = [];
    }
  },
  mounted() {
    // console.log("当前权限为", this.$route.meta.authority.button);
    this.pageNum = 1;
    this.pageSize = 15;
    this.searchData();
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">

.dialogButton {
  text-align: center;
  margin-top: 50px;
}

>>>.dialogHead .el-dialog .el-dialog__header {
  padding: 13px 13px 13px 30px !important;
  background-color: #0f6eff;
  color: #fff;
}

>>>.el-dialog__title {
  color: #fff;
}

>>>.el-icon-close {
  color: blue;

  &:hover {
    color: #fff;
  }
}
>>>.el-progress--line
  display: none !important;
>>>.el-progress__text
  display: none
</style>
